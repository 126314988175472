// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/loader-mts.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mts-application-form__title[data-v-f0f7ba46]{font-family:MTS Compact,Arial,sans-serif;font-size:24px;font-weight:500;line-height:30px;text-align:center;margin-bottom:16px}.mts-application-form__description[data-v-f0f7ba46]{font-size:17px;font-weight:400;line-height:24px;text-align:center;margin-bottom:20px}.mts-application-form__form[data-v-f0f7ba46]{display:flex;flex-direction:column}.mts-application-form__form-fields[data-v-f0f7ba46]{display:grid;grid-template-columns:1fr;grid-row-gap:16px;row-gap:16px}.mts-application-form__btn[data-v-f0f7ba46]{color:#fff;margin:20px auto 16px}.mts-application-form__info[data-v-f0f7ba46]{color:#626c77;font-size:12px;font-weight:400;line-height:17px;margin:0 auto;text-align:center}.mts-application-form__info a[data-v-f0f7ba46]{text-decoration:none;color:inherit;border-bottom:1px solid #626c77}.mts-application-form__info a[data-v-f0f7ba46]:hover{border-bottom:none}.loading button.mts-application-form__btn[data-v-f0f7ba46]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-color:rgba(188,195,208,.5);background-size:7%;background-repeat:no-repeat;background-position:50%;pointer-events:none}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
