// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/loader-mts.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mts-registration-form__form[data-v-0565c936]{display:flex;flex-direction:column}.mts-registration-form__form-fields[data-v-0565c936]{display:grid;grid-template-columns:100%;grid-row-gap:16px;row-gap:16px}.mts-registration-form__title[data-v-0565c936]{font-family:MTS Compact,Arial,sans-serif;font-size:24px;font-weight:500;line-height:28px;text-align:center;margin-bottom:16px}.mts-registration-form__description[data-v-0565c936]{font-size:17px;font-weight:400;line-height:24px;text-align:center;margin-bottom:20px}.mts-registration-form__btn[data-v-0565c936]{width:100%;margin:20px auto 8px}.mts-registration-form__btn.disabled[data-v-0565c936]{background:rgba(188,195,208,.5);color:#969fa8}.mts-registration-form__info[data-v-0565c936]{color:#626c77;font-family:MTS Compact,Arial,sans-serif;font-size:12px;font-style:normal;font-weight:400;line-height:17px;text-align:center}.mts-registration-form__info a[data-v-0565c936]{text-decoration:none;color:inherit;border-bottom:1px solid #626c77}.mts-registration-form__info a[data-v-0565c936]:hover{border-bottom:none}.mts-registration-form .loading button.mts-registration-form__btn[data-v-0565c936]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-color:rgba(188,195,208,.5);background-size:7%;background-repeat:no-repeat;background-position:50%;pointer-events:none}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
